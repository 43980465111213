/* --------------------------------------- Tint & Shade Functions --------------------------------------------- */

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* --------------------------------------- Tints & Shades Mapping --------------------------------------------- */
/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* --------------------------------------- Blue Tints & Shades --------------------------------------------- */

// Base
$color-blue: #0099ff; // hsb: 204/100/100 -- hsl: 204/100/50

// Tints
$color-blue--tint-5: tint($color-blue, 5);
$color-blue--tint-10: tint($color-blue, 10);
$color-blue--tint-15: tint($color-blue, 15);
$color-blue--tint-20: tint($color-blue, 20);
$color-blue--tint-25: tint($color-blue, 25);
$color-blue--tint-30: tint($color-blue, 30);
$color-blue--tint-35: tint($color-blue, 35);
$color-blue--tint-40: tint($color-blue, 40);
$color-blue--tint-45: tint($color-blue, 45);
$color-blue--tint-50: tint($color-blue, 50);
$color-blue--tint-55: tint($color-blue, 55);
$color-blue--tint-60: tint($color-blue, 60);
$color-blue--tint-65: tint($color-blue, 65);
$color-blue--tint-70: tint($color-blue, 70);
$color-blue--tint-75: tint($color-blue, 75);
$color-blue--tint-80: tint($color-blue, 80);
$color-blue--tint-85: tint($color-blue, 85);
$color-blue--tint-90: tint($color-blue, 90);
$color-blue--tint-95: tint($color-blue, 95);
$color-blue--tint-100: tint($color-blue, 100);

// Shades
$color-blue--shade-5: shade($color-blue, 5);
$color-blue--shade-10: shade($color-blue, 10);
$color-blue--shade-15: shade($color-blue, 15);
$color-blue--shade-20: shade($color-blue, 20);
$color-blue--shade-25: shade($color-blue, 25);
$color-blue--shade-30: shade($color-blue, 30);
$color-blue--shade-35: shade($color-blue, 35);
$color-blue--shade-40: shade($color-blue, 40);
$color-blue--shade-45: shade($color-blue, 45);
$color-blue--shade-50: shade($color-blue, 50);
$color-blue--shade-55: shade($color-blue, 55);
$color-blue--shade-60: shade($color-blue, 60);
$color-blue--shade-65: shade($color-blue, 65);
$color-blue--shade-70: shade($color-blue, 70);
$color-blue--shade-75: shade($color-blue, 75);
$color-blue--shade-80: shade($color-blue, 80);
$color-blue--shade-85: shade($color-blue, 85);
$color-blue--shade-90: shade($color-blue, 90);
$color-blue--shade-95: shade($color-blue, 95);
$color-blue--shade-100: shade($color-blue, 100);

/* --------------------------------------- Gray Shades & Tints--------------------------------------------- */

// Base
$color-gray: #d1dfed;

// Tints
$color-gray--tint-5: tint($color-gray, 5);
$color-gray--tint-10: tint($color-gray, 10);
$color-gray--tint-15: tint($color-gray, 15);
$color-gray--tint-20: tint($color-gray, 20);
$color-gray--tint-25: tint($color-gray, 25);
$color-gray--tint-30: tint($color-gray, 30);
$color-gray--tint-35: tint($color-gray, 35);
$color-gray--tint-40: tint($color-gray, 40);
$color-gray--tint-45: tint($color-gray, 45);
$color-gray--tint-50: tint($color-gray, 50);

// Shades
$color-gray--shade-5: shade($color-gray, 5);
$color-gray--shade-10: shade($color-gray, 10);
$color-gray--shade-15: shade($color-gray, 15);
$color-gray--shade-20: shade($color-gray, 20);
$color-gray--shade-25: shade($color-gray, 25);
$color-gray--shade-30: shade($color-gray, 30);
$color-gray--shade-35: shade($color-gray, 35);
$color-gray--shade-40: shade($color-gray, 40);
$color-gray--shade-45: shade($color-gray, 45);
$color-gray--shade-50: shade($color-gray, 50);
$color-gray--shade-55: shade($color-gray, 55);
$color-gray--shade-60: shade($color-gray, 60);
$color-gray--shade-65: shade($color-gray, 65);
$color-gray--shade-70: shade($color-gray, 70);
$color-gray--shade-75: shade($color-gray, 75);
$color-gray--shade-80: shade($color-gray, 80);
$color-gray--shade-85: shade($color-gray, 85);
$color-gray--shade-90: shade($color-gray, 90);
$color-gray--shade-95: shade($color-gray, 95);
$color-gray--shade-100: shade($color-gray, 100);


/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* --------------------------------------- App Color Mapping --------------------------------------------- */
/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */

$deprecated-aqua-1: #02fbf5;
$deprecated-aqua-2: #117f76;
$deprecated-aqua-3: #0BA4A6;
$deprecated-aqua-4: #01a6a2;

$color-white: #ffffff;
$color-black: #000000;
$color-pink: #e51e5a;

/* --------------------------------------- Blue --------------------------------------------- */

$color-highlight-lightest:  $color-blue--tint-45;
$color-highlight-light: $color-blue--tint-30;
$color-highlight: $color-blue--tint-15;
$color-highlight-dark: $color-blue--shade-5;
$color-highlight-darkest: $color-blue--shade-20;


$color-base-lightest: $color-blue--shade-35;
$color-base-light: $color-blue--shade-45;
$color-base: $color-blue--shade-60;
$color-base-dark: $color-blue--shade-75;
$color-base-darkest: $color-blue--shade-85;

/* --------------------------------------- Gray --------------------------------------------- */
$color-gray-lightest: $color-gray--tint-50;
$color-gray-light: $color-gray--shade-20;
$color-gray-regular: $color-gray--shade-35;
$color-gray-dark: $color-gray--shade-50;
$color-gray-darkest: $color-gray--shade-80;

/* --------------------------------------- Red --------------------------------------------- */
$color-red: #800020;
$color-red-light: #ff6363;
$color-red--shade-65: shade($color-red, 65);

/* --------------------------------------- Green --------------------------------------------- */
$color-green: #124429;
$color-green-light: #aee28e;
$color-green-dark: #124429;
$color-green--shade-65: shade($color-green, 65);

/* --------------------------------------- Green 2 --------------------------------------------- */
$color-green-v2: #124429;

/* --------------------------------------- Yellow --------------------------------------------- */
$color-yellow: #B34e24;
$color-warning: $color-yellow;
$color-validation: $color-yellow;
$color-pending: $color-yellow;
$color-wrap-up: $color-yellow;

// shades
$color-yellow--shade-50: shade($color-yellow, 50);

/////////////////////////////////////////////////////////////////
////          LIVE DB COLORS + Interaction log colors
/////////////////////////////////////////////////////////////////

$color-anakiwa: #51dfef;            //  CALLS
$color-celeste: #a4f3fc;            //  ABANDONED CALLS
$color-light-pink: #fcada4;         //  ANSWERED
$color-bright-lilac: #c18ffd;       //  SMS
$color-brilliant-rose: #fd328d;     //  EMAILS
$color-electric-violet: #633ebb;    //  CHATS & ABANDONED CHATS
$color-cornflower-blue: #0067cc;    //  CALLBACKS
$color-bright-turquoise: #00baff;   //  WEBSITE
$color-azure-radiance: #4992ff;     //  CALLS FORWARDED
$color-marigold-yellow: #ffcb51;    //  VOICEMAIL & MAILBOX (Same thing)
$color-bright-green: 	#0fa911;      //  Instant Message
$color-salmon: #fd7557;             //  MISSED
