
.flex {
  display: flex;

  &--justify {
    &-center {
      justify-content: center;
    }
    &-around {
      justify-content: space-around;
    }
    &-between {
      justify-content: space-between;
    }
    &-evenly {
      justify-content: space-evenly;
    }
  }

  &--position {
    &-center-h {
      align-content: center;
    }
    &-around {
      align-content: space-around;
    }
    &-center-v {
      align-self: center;

      &-flex-start--height-500 {
        @media only screen and (max-height: 500px) {
          align-self: flex-start;
        }
      }
    }
    &-end-v {
      align-self: flex-end;
    }
    &-end-h {
      justify-content: flex-end;
    }
    &-flex-start {
      align-self: flex-start;
    }
  }

  &--direction {
    &-cols {
      flex-direction: column;
    }
    &-rows {
      flex-direction: row;
    }
  }

  &--grow {
    &-0 {
      flex-grow: 0;
    }
    &-1 {
      flex-grow: 1;
    }
  }

  &--wrap {
    flex-wrap: wrap;

    &-none {
      flex-wrap: nowrap;
    }
  }

  &--fill {
    flex-grow: 1;
  }
  &--align {
    &-center {
      align-items: center;
    }
    &-self {
      &-stretch {
        align-self: stretch;
      }
    }
  }

  &--basis-1-1 {
    flex: 1 1 0px;
  }
}

.list {
  &--style {
    &-none {
      list-style-type: none;
    }
  }
}

.pad {
  &--top {
    &-5 {
      padding-top: 5px;
    }
    &-7 {
      padding-top: 7px;
    }
    &-10 {
      padding-top: 10px;
    }
    &-20 {
      padding-top: 20px;
    }
    &-30 {
      padding-top: 30px;
    }
    &-100 {
      @media (max-width: 600px), (max-height: 600px) {
        padding-top: 20px!important;
      }
      @media (min-width: 601px), (min-height: 601px) {
        padding-top: 100px;
      }
    }
  }
  &--bottom {
    &-5 {
      padding-bottom: 5px;
    }
    &-7 {
      padding-bottom: 7px;
    }
    &-10 {
      padding-bottom: 10px;
    }
    &-20 {
      padding-bottom: 20px;
    }
    &-23 {
      padding-bottom: 23px;
    }
    &-40 {
      padding-bottom: 40px;
    }
    &-50 {
      padding-bottom: 50px;
    }
    &-100 {
      @media (max-width: 600px), (max-height: 600px) {
        padding-bottom: 20px!important;
      }
      @media (min-width: 601px), (min-height: 601px) {
        padding-bottom: 100px;
      }
    }
  }
  &--left {
    &-5 {
      padding-left: 5px;
    }
    &-10 {
      padding-left: 10px;
    }
    &-20 {
      padding-left: 20px;
    }
    &-40 {
      padding-left: 40px;
    }
    &-100 {
      @media (max-width: 600px), (max-height: 600px) {
        padding-left: 20px!important;
      }
      @media (min-width: 601px), (min-height: 601px) {
        padding-left: 100px;
      }
    }
  }
  &--right {
    &-5 {
      padding-right: 5px;
    }
    &-10 {
      padding-right: 10px;
    }
    &-20 {
      padding-right: 20px;
    }
    &-100 {
      @media (max-width: 600px), (max-height: 600px) {
        padding-right: 20px!important;
      }
      @media (min-width: 601px), (min-height: 601px) {
        padding-right: 100px;
      }
    }
  }
  &--60 {
    padding: 60px;
  }
  &--20 {
    padding: 20px;
  }
  &--10 {
    padding: 10px;
  }
  &--8 {
    padding: 8px;
  }
  &--5 {
    padding: 5px;
  }
  &--0 {
    padding: 0px;
  }
}

.mar {
  &--0 {
    margin: 0;
  }
  &--5 {
    margin: 5px;
    &__force {
      margin: 5px!important;
    }
  }
  &--top {
    &-10 {
      margin-top: 10px;
    }
    &-20 {
      margin-top: 20px;
    }
  }

  &--right {
    &-5 {
      margin-right: 5px;
    }
  }

  &--bottom {
    &-5 {
      margin-bottom: 5px;
    }
    &-10 {
      margin-bottom: 10px;

      &__force {
        margin-bottom: 10px!important;
      }
    }
    &-20 {
      margin-bottom: 20px;
    }
    &-40 {
      margin-bottom: 40px;
    }
    &-100 {
      margin-bottom: 100px;
    }
    &-200 {
      margin-bottom: 200px;
    }
  }

  &--left {
    &-5 {
      margin-left: 5px;
    }
    &-10 {
      margin-left: 10px;
    }
    &-60 {
      margin-left: 60px;
    }
  }
}

.hoverable {
  cursor: pointer;
}

.height {
  &--20 {
    height: 20px;
    
    &__force {
      height: 20px!important;
    }
  }
  &--30 {
    height: 30px;
    
    &__force {
      height: 30px!important;
    }
  }
  &--10 {
    height: 10px;
    
    &__force {
      height: 10px!important;
    }
  }
  &--full {
    height: 100%;
  }
}

.background {
  &--blue {
    &-45 {
      background-color: $color-blue--shade-45;
    }
    &-50 {
      background-color: $color-blue--shade-50;
    }
    &-60 {
      background-color: $color-blue--shade-60;
      &:hover {
        background-color: $color-blue--shade-45;
      }
      &__force {
        background-color: $color-blue--shade-60!important;
      }
    }
    &-70 {
      background-color: $color-blue--shade-70;
    }
    &--shade {
      &-7 {
        background-color: rgba($color-blue--shade-75, 0.7);
      }
    }
    &-dark {
      background-color: $color-base-dark;
    }
  }
  &--red {
    background: $color-red;
  }
  &--orange {
    background: $color-yellow;
  }
  &--green {
    background: $color-green-dark;
  }
  &--grey {
    background: rgb(128, 128, 128);
  }
}

.border {
  &-left {
    &--blue {
      border-left: 1px solid $color-blue;

      &-45 {
        border-left: 1px solid $color-blue--shade-45;
      }

      &-75 {
        border-left: 1px solid $color-blue--shade-75;
      }
    }
  }
  &-right  {
    &--blue {
      border-right: 1px solid $color-blue;

      &-45 {
        border-right: 1px solid $color-blue--shade-45;
      }
    }
  }
  &-top  {
    &--blue {
      border-top: 1px solid $color-blue;

      &-45 {
        border-top: 1px solid $color-blue--shade-45;
      }
    }
  }
  &-bottom  {
    &--blue {
      border-bottom: 1px solid $color-blue;

      &-dark {
        border-bottom: 1px solid $color-base-dark;
      }
      &-45 {
        border-bottom: 1px solid $color-blue--shade-45;

        &__force {
          border-bottom: 1px solid $color-blue--shade-45!important;
        }

        &__last-child {
          &:last-child {
            border-bottom: 1px solid $color-blue--shade-45;
          }
        }
      }
    }
  }

  &-radius {
    &--4 {
      border-radius: 4px;
    }
  }
  &--none {
    border: none;
  }
}

.pos {
  &--fixed {
    position: fixed;
    z-index: 1;
   
    &-full {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &--top {
    &-100 {
      top: 100%;
    }
    &-120 {
      top: 120%;
    }
  }
  &--right {
    &-0 {
      right: 0;
    }
  }
}

.z-index {
  &--20 {
    z-index: 20;
  }
  &--40 {
    z-index: 40;
  }
}

.is {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }

  &-hidden {
    display: none;
  }
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

input {
  color: $color-white;
}

a {
  color: rgba(38,168,255,.5);
  line-height: 1rem;
  font-size: 1rem;
}

.text {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }

  &--color {
    &-white {
      color: #FFF;

      &__force {
        color: #FFF!important;
      }
    }
    &-blue {
      color: $color-blue;
    }
    &-red {
      color: $color-red;
    }
    &-red-light {
      color: $color-red-light;
    }
    &-green {
      color: $color-green-dark;
    }
    &-green-light {
      color: $color-green-light;
    }
  }

  &--wrap {
    overflow-wrap: break-word;

    &-none {
      white-space: nowrap;
    }
  }
  &--inline {
    display: inline-block;
  }
  &--ellipse {
    text-overflow: ellipsis;
  }
}

.font {
  &--zailab {
    font-family: sans-serif,Roboto;
  }
  &--size {
    &-20 {
      @media (max-width: 600px), (max-height: 600px) {
        font-size: 1.5rem!important;
      }
      @media (min-width: 601px), (min-height: 601px) {
        font-size: 2rem;
      }
    }
    &-15 {
      font-size: 1.5rem;
    }
    &-9 {
      font-size: 0.9rem;
    }
    &-8 {
      font-size: 0.8rem;
    }
    &-7 {
      font-size: 0.7rem;
    }
    &-6 {
      font-size: 0.6rem;
    }
  } 
}

.overflow {
  &--hidden {
    overflow: hidden;
    &-y {
      overflow-y: hidden;
    }
    &-x {
      overflow-x: hidden;
    }
  }
  &--auto {
    overflow: auto;
    &-y {
      overflow-y: auto;
    }
    &-x {
      overflow-x: auto;
    }
  }
}

.zbutton {
  display: block;
  background-color: rgba(38,168,255,.1);
  box-shadow: inset 0 0 0 2px #26a8ff;
  border: none;
  border-radius: 3px;
  padding: 12px;
  font-size: 1.1rem;
  width: 100%;
  max-width: 140px;
  transition: 175ms ease-in-out;
  color: #fff;

  &-pink {
    font-weight: 700;
    background-color: #e51e5a;
    box-shadow: inset 0 0 0 2px #e51e5a;
  }
}

.width {
  &--full {
    width: 100%;
    max-width: unset;

    &-max-width--500 { // todo: move these media queries to different classes
      @media only screen and (min-width: 400px) {
        width: 400px!important;
      }
    }
  }
  &--100 {
    width: 100px;
  }
  &--90 {
    width: 90px;
  }
  &--80 {
    width: 80px;
  }
  &--70 {
    width: 70px;
  }
  &--60 {
    width: 60px;
  }
  &--40 {
    width: 40px;
  }
  &--20 {
    width: 20px;
    &__force {
      width: 20px!important;
    }
  }
  &--10 {
    width: 10px;
    
    &__force {
      width: 10px!important;
    }
  }

  &--max {
    &-165 {
      max-width: 165px;
    }
  }
  &--min {
    &-200 {
      min-width: 200px;
    }
    &-130 {
      min-width: 130px;
    }
    &-160 {
      min-width: 160px;
    }
    &-90 {
      min-width: 90px;
    }
    &-20 {
      min-width: 20px;
    }
  }
  &--min {
    &-25percent {
      min-width: 25%;
    }
  }
}

.color {
  &-validation {
    color: #ffa800;
  }
}

// @media only screen and (max-width: 700px) {
//   .panel {
//     width: 100%;
//   }
// }

.rounded {
  &-5 {
    border-radius: 5px;
  }
  &-10 {
    border-radius: 10px;
  }
}

a {
  text-decoration: none;
}

.overflow {
  overflow: auto;
}

.u-scroll-bar {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($color-base, 0.9);
    border-radius: 4px;
    transition: 1s ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-blue--shade-75;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-highlight, 0.8);
    }
  }
  &--thin {
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    }
  }
  &--thick {
    &::-webkit-scrollbar {
      size: 6px;
      margin-left: 0.5rem;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-highlight, 0.5);
      }
    }
  }
  &--white {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-white, 0.5);
    }
    &::-webkit-scrollbar-track {
      background-color: rgba($color-white, 0.3);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-white, 0.8);
      }
    }
  }
  &--blue {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-blue, 1);
    }
    &::-webkit-scrollbar-track {
      background-color: $color-blue--shade-60;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba($color-blue, 0.8);
      }
    }
  }
  &--offset {
    width: calc(100% + 6px);
    padding-right: 6px;
  }
  &--offset-2 {
    width: calc(100% + 2rem);
    padding-right: 2rem;
  }
}

.shadow {
  &--inset {
    &-right {
      box-shadow: -160px 0px 103px -54px white inset;
      -webkit-box-shadow: -160px 0px 103px -54px white inset;
      -moz-box-shadow: -160px 0px 103px -54px white inset;
    } 
  }
  &--outset {
    &-dark {
      box-shadow: 0 0 3px 1px #001726;
    }
  }
}

// UNSET STYLES | keep at bottom
.unset {
  &-overflow {
    overflow: unset!important;
  }
  &-hoverable {
    cursor: unset!important;

    &-background {
      &:hover {
        background: unset!important;
      }
    }
  }
  &-background {
    background: unset!important;
  }
  &-max-height {
    max-height: unset!important;
  }
  &-max-width {
    max-width: unset!important;
  }
}

.fill {
  &--green {
    fill: $color-green-light;
  }
  &--red {
    fill: $color-red-light;
  }
  &--white {
    fill: white;
  }
}

.contentHeight {
  &--200 {
    height: calc(100vh - 244px);
  }
  &--120 {
    height: calc(100vh - 124px);
  }
}

// greys out element and makes it un-clickable
.u-faded-content {
  opacity: 0.3;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  pointer-events: none;
  &:hover {
    opacity: 0.3;
    -webkit-filter: none;
  }
}

.up-arrow::before {
  content: "";
  position: absolute;
  background-color: $color-blue--shade-70;
  border-bottom: none;
  border-right: none;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 0.75rem;
  transform: rotateZ(45deg);
}

input:-internal-autofill-selected {
  background-color: transparent!important;
  color: #FFFFFF!important;
  border-image: unset!important;
  border-left: 1px solid rgba(0, 61, 102, 0.8)!important;
  border-right: 1px solid rgba(0, 61, 102, 0.8)!important;


}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.bold--900 {
  font-weight: 900!important;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
}

@keyframes marquee {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.rotate {
  &--180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}