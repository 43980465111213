text {
  tspan {
    fill: white;
    font-size: 0.7rem;
  }
}

.recharts-layer {
  position: relative;
}

