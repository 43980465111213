@import "./assets/styles/Colors.scss";
@import "./assets/styles/Globals.scss";
@import "./assets/styles/Graphs.scss";

html,
body {
  width: 100%;
  height: 100%;
  background: url(./assets/images/pattern_square.png), linear-gradient(to right, rgba($color-blue--shade-95,1) 0%, $color-blue--shade-85 50%, rgba(0,6,10,1) 100%);
  font-family: sans-serif,Roboto;
  font-size: 1.1rem;
}

.background-planet {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 540px;
  height: 100%;
  max-height: 820px;
  background: url(./assets/images/background_planet.png);
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.65;
  &--with-glow {
    opacity: 1;
    background: url(./assets/images/background_planet.png),radial-gradient(circle at -47% 94%, black 0%, rgba(0,31,54,.75) 62%, rgba(0,31,54,.75) 68%,transparent 70%);
    filter: contrast(1.1);
  }
}

.logo {
  margin: 0 auto;
  background-image: url(./assets/images/zailab-logo-big.png);
  background-size: contain;
  background-repeat: no-repeat;

  &--size-medium {
    width: 166px;
    height: 90px;
  }
}

.icon-input {
  padding: 2px 6px;
  padding-left: 32px;
  font-size: 1.1rem;
  line-height: 1rem;
  width: calc(100% - 40px);
  border: solid;
  background-color: transparent;
  border-bottom: 1px solid rgba(0,61,102,.8);
  border-width: 1px;
  border-top: none;
  border-image: linear-gradient(to top,rgba(38,168,255,.4) 0,rgba(38,168,255,.4) 10px,transparent 10px) 1;
  &:focus {
    border-image: linear-gradient(to top,rgba(38,168,255,.8) 0,rgba(38,168,255,.8) 10px,transparent 10px) 1;
  }
}

.icon {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center;
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;

  &--envelope-closed {
    background-image: url(./assets/images/svg/envelope-closed.svg);
  }
  &--lock {
    background-image: url(./assets/images/svg/lock.svg);
  }
  &--power {
    background-image: url(./assets/images/svg/power.svg);
  }
  &--search {
    background-image: url(./assets/images/svg/search.svg);
  }
  &--chevron-down {
    background-image: url(./assets/images/svg/chevron-down.svg);
  }
  &--refresh {
    background-image: url(./assets/images/svg/refresh.svg);
  }

  &--color {
    &-gray {
      filter: grayscale(10) brightness(10) contrast(10);
    }
  }
}

.load-bar {
  position: absolute;
  top: 0px;
  left: 2%;
  width: 96%;
  height: 5px;
}


.o-ztext-input__icon {
  width: 24px;
  height: 15px;
  padding-right: 4px;
  padding-left: 4px;
  position: absolute;
  top: calc(50% - 3px);
  transform: translateY(-50%);
  opacity: .4;
}

.error {
  font-size: 0.9rem!important;
  &--field {
    &-bottom {
      position: absolute;
      display: block;
      width: 100%;
      left: 0px;
      bottom: -5px;
      font-size: 1.1rem;
      text-align: left;
      padding-left: 14px;
      z-index: 5;
      color: #ffa800!important;
    }
  }
}

// loader 
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  margin: 0 auto;
  border-radius: 10px;
  padding: 1px;
}
.loader:before {
  content: '';
  border-radius: 10px;
  position: absolute;
  top: -4px; 
  right: -4px; 
  bottom: -4px; 
  left: -4px;
}
.loader .loaderBar { 
  position: absolute;
  border-radius: 10px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: $color-highlight; 
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.name-col {
  min-width: 70px;

  @media only screen and (max-width: 400px) {
    width: 70px!important;
  }
  @media only screen and (min-width: 401px) and (max-width: 450px) {
    width: 100px!important;
  }
  @media only screen and (min-width: 451px) {
    width: 150px!important;
  }
}